<template>
    <component :is="template">
        <v-responsive class="member-points py-9 px-0 px-md-3">
            <v-layout justify-center><span class="headline mb-8">포인트 선물내역</span></v-layout>
            <v-divider class="mt-6"/>
            <v-data-table :items="gifts" :headers="giftsHeaders" :mobile-breakpoint="-1" disable-pagination disable-sort hide-default-footer>
                <span slot="item.amount" slot-scope="{item}">{{ item.amount.format() }} 원</span>
                <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            </v-data-table>
            <v-divider />
            <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" class="mt-6" />
        </v-responsive>
    </component>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {

            filter: {
                type: "all"
            },

            page: 1,
            pageCount: 0,
            limit: 10,

            gifts: [],
            giftsHeaders: [
                { text: "보낸사람", value: "sender.username", align: "center", width: 100 },
                { text: "받는사람", value: "receiver.username", align: "center" },
                { text: "주문금액", value: "amount", align: "center", width: 150 },
                { text: "상태", value: "status", align: "center", width: 100 },
                { text: "선물일자", value: "createdAt", align: "center", width: 180 },
            ]
        }
    },
    mounted(){
        if(this.logoff) this.$router.push(`/login`);
        this.init();
    },
    methods: {
        async init(){
            await this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, gifts } = await api.v1.me.gifts.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter
            });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.gifts = gifts;
        }
    },
    computed: {
        template(){ return () => import(`@/templates/${this.$theme.pages.point}`) },
        
        logoff(){
            return !this.$store.state.accessToken;
        }
    }
}
</script>

<style scoped>
.member-points .title {
    font-size: 22px !important;
    font-weight: 600;
}
.member-points >>> .v-data-table__mobile-row {
    height: auto;
}
</style>